import { defaultJuniper } from './default-juniper-uk';
import { sharedDevEuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultJuniper,
  ...sharedDevEuEnv,
  environment: 'development',
  apiUrl: 'https://api.admin.eu.dev.eucalyptus.vc',
  restApiUrl: 'https://admin-api.dev.myjuniper.co.uk',
  assetsUrl: 'https://assets.dev.myjuniper.co.uk',
  logoUrl: 'https://assets.dev.myjuniper.co.uk/juniper-logo.svg',
  shopifyHostname: 'dev-myjuniper-uk.myshopify.com',
  userAppUrl: 'https://app.dev.myjuniper.co.uk',
  doctorsAppUrl: 'https://prescribers.dev.myjuniper.co.uk',
  googleOAuthClientId:
    '966685249037-0ikbs91jfl2gns80jlrsai618ak8smp9.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.dev.myjuniper.co.uk',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee4',
  healthCoachingFlows: true,
};
